import React, { useEffect } from 'react'

const Bobby = () => {

	useEffect(() => {
		// this loads the script...
		// eslint-disable-next-line no-unused-vars, no-undef
		const bobbyScript = require('../bobbyScript.js')
	}, [])

	return <div />
}

export default Bobby