if(typeof window !== 'undefined' && window.document && window.document.createElement) {
	const script = document.createElement('script')
	script.src = '//better-hr-726f6e7.prod.1000grad.de/chat/assets/js/main.js'
	script.id = 'tgd-chat-script'

	script.onload = async function () {
		// eslint-disable-next-line no-undef
		window.$chatbot = new TGDChatBot({
			'viewMode':'app',
			'identifier':'43dd3617-a2b7-4310-859a-7a93c9ef66d4',
			'headerText': 'Bobby',
			'headerLogo': 'https://bobby.betterhr.de/imgMetaTags/no-logo.png',
			'botAvatar': 'https://bobby.betterhr.de/imgMetaTags/bobbyAvatar.png',
			'userAvatar': 'https://bobby.betterhr.de/imgMetaTags/userAvatar.png',
			'botMessageBorderRadius': '10px',
			'userMessageBorderRadius': '10px',
			'userMessageColor': 'rgb(207, 255, 255)',
			'userMessageTextColor': '#000'
		})
		await window.$chatbot.startApp()
		console.log('⭐️⭐️ Bobby connected ⭐️⭐️')
	}

	document.getElementsByTagName('body')[0].appendChild(script)

	setTimeout(function() {
		// custom bot styles
		let link = document.createElement('link')
		link.href = '/bot-styles.css'
		link.type = 'text/css'
		link.rel = 'stylesheet'
		link.media = 'screen,print'
		document.getElementsByTagName('head')[0].appendChild(link)
	}, 300)
}